import { Avatar } from '@mui/material';
import logo from './logo.png';

export function Logo() {
  return (
    <Avatar
      src={logo}
      sx={{
        width: { xs: 64, md: 96 },
        height: { xs: 64, md: 96 },
      }}
    />
  );
}
