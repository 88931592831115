import { Paper, Stack, TextField, Typography, Box, Alert, useTheme } from '@mui/material';
import { Logo } from 'images/Logo';
import { Link, WaitButton } from 'components';
import { Form, mustBeEmail } from 'components/form';
import { AuthPage } from 'app/layout';
import { useAsyncState } from 'app/hooks';
import { useAppDispatch, resetPassword } from 'app/store';
import type { Validate } from 'components/form';

type ResetPasswordData = {
  email: string;
};

const validators: Record<string, Validate> = {
  email: mustBeEmail(),
};

export default function ResetPassword() {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [resetPasswordState, success, error, waiting] = useAsyncState();
  const handleSubmit = (data: ResetPasswordData) => {
    dispatch(resetPassword(data.email)).unwrap().then(success, error);
    waiting();
  };

  return (
    <AuthPage>
      <Logo />
      <Paper sx={{ my: 4, px: 4, py: 6, width: { xs: '90%', sm: 500 } }}>
        <Box sx={{ textAlign: 'center', mb: 3 }}>
          <Typography variant="h5">RESET PASSWORD</Typography>
          {resetPasswordState.status === 'failure' && (
            <Alert severity="error">{resetPasswordState.error.code || 'Reset password failed'}</Alert>
          )}
        </Box>

        {resetPasswordState.status === 'fulfilled' ? (
          <Alert severity="info">
            Your will shortly receive an email with instructions on how to reset your password. Go{' '}
            <Link href="/">Home</Link>
          </Alert>
        ) : (
          <Form<Partial<ResetPasswordData>>
            initialData={{}}
            onSubmit={(data) => handleSubmit(data as ResetPasswordData)}
            validators={validators}>
            {(state, updateField) => {
              return (
                <Stack spacing={3}>
                  <TextField
                    id="email"
                    value={state.data.email || ''}
                    onChange={(e) => updateField('email', e.target.value)}
                    error={Boolean(state.errors['email'])}
                    helperText={state.errors['email']}
                    required
                    fullWidth
                    label="Email"
                    autoComplete="username"
                  />
                  <WaitButton
                    id="submit"
                    type="submit"
                    variant="contained"
                    wait={resetPasswordState.status === 'pending'}>
                    Reset password
                  </WaitButton>
                </Stack>
              );
            }}
          </Form>
        )}
      </Paper>
    </AuthPage>
  );
}
